<template>
  <div>
    <ManageScholarshipsMaster1 />
  </div>
</template>
<script>
import ManageScholarshipsMaster1 from "@/components/ManageScholarshipsMaster1/ManageScholarshipsMaster1";
export default {
  components: {
    ManageScholarshipsMaster1,
  },
  created() {},
};
</script>
